import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { TransitionLevel as TransitionLevelChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik } from 'formik';
import { TransitionLevelPicker } from '../../Fields';
import FormikEffect from '../../../utils/FormikEffect';

import { schema } from './schema';

type Props = ChunkFormProps<TransitionLevelChunk>;

export const TransitionLevel: React.FC<Props> = ({ onChange }) => {
  const { loading, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    return {
      transitionLevel: '',
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ values, isValid, errors }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new TransitionLevelChunk({
                  transitionLevel: parseInt(values.transitionLevel, 10),
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <TransitionLevelPicker
              variant="outlined"
              name="transitionLevel"
              label="Transition level"
              required={true}
              margin="normal"
              fullWidth={true}
              disabled={loading}
            />
          </>
        );
      }}
    </Formik>
  );
};
