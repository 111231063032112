import React, { useState, useCallback, useMemo } from 'react';

import { Ctx, useFetchAirportInformation } from './useAirportInformation';

import {
  TextField,
  Box,
  CircularProgress,
  Typography,
  makeStyles,
  Theme,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Divider,
  IconButton,
  Button,
} from '@material-ui/core';

import { Forms } from './chunks';

import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TopLevelChunk } from './chunks/Chunk';
import { AtisBuilder } from './chunks';
import Preview from './Preview';

const useStyles = makeStyles((theme: Theme) => ({
  radioGroup: {
    flexDirection: 'row',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Form: React.FC<{}> = () => {
  const classes = useStyles();
  const [selectedAirport, setSelectedAirport] = useState<string>('');

  const [chunks, setChunks] = useState<
    Array<null | { chunk: null | TopLevelChunk; isValid: boolean }>
  >([]);

  const setChunkAtIdx = useCallback(
    (
      idx: number,
      { chunk, isValid }: { chunk: TopLevelChunk | null; isValid: boolean },
    ) => {
      setChunks(prev => {
        const newState = [...prev];
        newState[idx] = { chunk, isValid };
        return newState;
      });
    },
    [setChunks],
  );

  const airportInformation = useFetchAirportInformation({
    icaoId: selectedAirport,
  });

  const atis = useMemo(
    () =>
      new AtisBuilder({
        icaoId: selectedAirport || null,
        chunks: chunks.map(c => c?.chunk),
      }),
    [selectedAirport, chunks],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexShrink={1}
        minHeight={0}
        flexWrap="wrap"
      >
        <Box
          display="flex"
          flexDirection="column"
          minWidth={350}
          flexGrow={1}
          padding={1}
          flexBasis={700}
          overflow="auto"
        >
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box marginRight={1}>
                  {!airportInformation || !!airportInformation.error ? (
                    <ErrorIcon color="error" />
                  ) : airportInformation.loading ? (
                    <CircularProgress size={20} />
                  ) : !!airportInformation.data ? (
                    <CheckIcon color="action" />
                  ) : null}
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography>Pick an airport</Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {airportInformation.loading ? (
                      'Loading ...'
                    ) : !!airportInformation.error ? (
                      'An error happened !'
                    ) : !!airportInformation?.data?.weather?.METAR ? (
                      <>METAR: {airportInformation.data.weather.METAR}</>
                    ) : null}
                  </Typography>
                </Box>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <TextField
                  name="airport"
                  value={selectedAirport}
                  label="Airport"
                  variant="outlined"
                  error={!!airportInformation.error}
                  helperText={airportInformation.error?.message || 'eg. LFBO'}
                  onChange={event => {
                    const value = event.target.value;
                    setSelectedAirport(value.trim().toUpperCase());
                  }}
                  margin="normal"
                  required={true}
                  fullWidth={true}
                />
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Ctx.Provider value={airportInformation}>
            {[
              {
                title: 'Airport / ATIS info',
                Component: Forms.Airport,
                required: true,
              },
              {
                title: 'Runway(s) in use',
                Component: Forms.Runways,
                required: true,
              },
              {
                title: 'Transition level',
                Component: Forms.TransitionLevel,
                required: true,
              },
              { title: 'Wind', Component: Forms.Wind, required: true },
              {
                title: 'Sky coverage',
                Component: Forms.SkyCoverage,
                required: true,
              },
              {
                title: 'Visibility',
                Component: Forms.Visibility,
                required: true,
              },
              {
                title: 'Temperatures',
                Component: Forms.Temperature,
                required: true,
              },
              { title: 'QNH / QFE', Component: Forms.QNH, required: true },
            ].map(({ title, Component, required }, idx) => {
              const isValid = !!chunks[idx]?.isValid;
              const chunk = chunks[idx]?.chunk;

              const errored = !isValid || (required && !chunk);

              return (
                <ExpansionPanel defaultExpanded={required} key={idx}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      {errored && (
                        <ErrorIcon
                          color="error"
                          className={classes.marginRight}
                        />
                      )}
                      {isValid && <CheckIcon className={classes.marginRight} />}
                      <Box display="flex" flexDirection="column">
                        <Typography color={errored ? 'error' : undefined}>
                          {title}
                        </Typography>
                        {isValid && !!chunk && (
                          <Typography variant="subtitle1" color="textSecondary">
                            <i>
                              {chunk.toString({
                                lang: 'en',
                                format: 'readable',
                              })}
                            </i>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                      <Component
                        key={idx}
                        onChange={({
                          chunk,
                          isValid,
                        }: {
                          chunk: null | TopLevelChunk;
                          isValid: boolean;
                        }) => {
                          setChunkAtIdx(idx, { chunk, isValid });
                        }}
                      />
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </Ctx.Provider>
        </Box>

        <Box
          display="flex"
          padding={1}
          flexDirection="column"
          minWidth={350}
          maxWidth={500}
          flexGrow={1}
          flexBasis={350}
        >
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Readable
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box display="flex" flexDirection="column">
                <Typography color="textSecondary">
                  {atis
                    .toString({ lang: 'en', format: 'readable' })
                    .split('\n')
                    .map((s, idx) => (
                      <React.Fragment key={idx}>
                        {s}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
                <Divider className={classes.spacer} />
                <Typography color="textSecondary">
                  {atis
                    .toString({ lang: 'fr', format: 'readable' })
                    .split('\n')
                    .map((s, idx) => (
                      <React.Fragment key={idx}>
                        {s}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Speakable
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box display="flex" flexDirection="column">
                <Typography color="textSecondary">
                  {atis
                    .toString({ lang: 'en', format: 'speakable' })
                    .split('\n')
                    .map((s, idx) => (
                      <React.Fragment key={idx}>
                        {s}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
                <Divider className={classes.spacer} />
                <Typography color="textSecondary">
                  {atis
                    .toString({ lang: 'fr', format: 'speakable' })
                    .split('\n')
                    .map((s, idx) => (
                      <React.Fragment key={idx}>
                        {s}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              SSML
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box display="flex" flexDirection="column">
                <Typography color="textSecondary">
                  {atis
                    .toString({ lang: 'en', format: 'ssml' })
                    .split('\n')
                    .map((s, idx) => (
                      <React.Fragment key={idx}>
                        {s}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
                <Divider className={classes.spacer} />
                <Preview builder={atis} lang="en" />
                <Divider className={classes.spacer} />
                <Typography color="textSecondary">
                  {atis
                    .toString({ lang: 'fr', format: 'ssml' })
                    .split('\n')
                    .map((s, idx) => (
                      <React.Fragment key={idx}>
                        {s}
                        <br />
                      </React.Fragment>
                    ))}
                </Typography>
                <Divider className={classes.spacer} />
                <Preview builder={atis} lang="fr" />
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              JSON
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" component="pre">
                {JSON.stringify(atis.toPlainObject(), null, 2)}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      </Box>
    </>
  );
};

export default Form;
