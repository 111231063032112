import * as Yup from 'yup';

export const schema = Yup.object().shape({
  layers: Yup.array().of(
    Yup.object().shape({
      coverage: Yup.string()
        .oneOf(
          ['broken', 'overcast', 'scattered', 'few'],
          'Invalid cloud coverage',
        )
        .required('Required'),
      altitude: Yup.number().required('Required'),
    }),
  ),
});
