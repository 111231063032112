import React, { useCallback, useRef } from 'react';
import { useConfig } from '../config';
// @ts-ignore
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import { Box } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router';
import qs from 'query-string';

export const Playground: React.FC<{}> = () => {
  const [config] = useConfig();
  const myGraphiQL = useRef<any>(null);
  const graphQLFetcher = useCallback(
    (params: any) => {
      const url = new URL('graphql', config.apiBaseUrl);

      return fetch(url.href, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      }).then((response: any) => {
        if (myGraphiQL.current) {
          myGraphiQL.current.refresh();
        }

        return response.json();
      });
    },
    [config.apiBaseUrl, myGraphiQL],
  );

  const location = useLocation();
  const { q } = qs.parse(location.search);
  const query = !!q ? atob(q as string) : '';
  const history = useHistory();

  return (
    <Box display="flex" flexGrow={1} flexShrink={1}>
      <GraphiQL
        ref={myGraphiQL}
        fetcher={graphQLFetcher}
        query={query}
        docExplorerOpen={true}
        onEditQuery={(q: string) => {
          history.replace({
            search: qs.stringify({
              q: btoa(q),
            }),
          });
        }}
      />
    </Box>
  );
};
