import React from 'react';

import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

import { letters as aeroAlphabets } from '../../utils/aeroAlphabets';

export const AtisVersionPicker: React.FC<Omit<
  React.ComponentProps<typeof TextField>,
  'select'
>> = ({ ...rest }) => {
  return (
    <TextField select={true} {...rest}>
      {aeroAlphabets.map(letter => (
        <MenuItem value={letter} key={letter}>
          {letter}
        </MenuItem>
      ))}
    </TextField>
  );
};
