import React from 'react';

export type Config = {
  airport: string | null;
  apiKey: string | null;
  apiBaseUrl: string;
};

export type Context = {
  config: Config;
  setConfig: (config: Config) => void;
};

export const Ctx = React.createContext<null | Context>(null);
