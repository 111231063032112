import {
  Chunk,
  BaseSerializeOptions,
  SerializeOptions,
  TopLevelChunk,
} from '../Chunk';
import { Runway } from '../Elements/Runway';
import { createSerializer } from '../serializers';
import { AtisObject } from '../types';

type Props = {
  departureRunway: Runway;
  arrivalRunway: null | Runway;
};

export class Runways extends Chunk
  implements TopLevelChunk<AtisObject['runways']> {
  type = 'Runways';
  private properties: Props;

  constructor({
    departureRunway,
    arrivalRunway,
  }: {
    departureRunway: string;
    arrivalRunway: null | string;
  }) {
    super();
    this.properties = {
      departureRunway: new Runway(departureRunway),
      arrivalRunway: !!arrivalRunway ? new Runway(arrivalRunway) : null,
    };
  }

  private serializeSingleRunway<T>({
    serializer,
    lang,
    format,
  }: SerializeOptions<T>) {
    const { departureRunway } = this.properties;

    switch (lang) {
      case 'fr':
        return serializer`Piste en service ${departureRunway}.`;
      case 'en':
      default:
        return serializer`Runway in use ${departureRunway}.`;
    }
  }

  private serializeDepArr<T>({
    serializer,
    lang,
    format,
  }: SerializeOptions<T>) {
    const { departureRunway, arrivalRunway } = this.properties;

    switch (lang) {
      case 'fr':
        return serializer`Décollage ${departureRunway}, atterrissage ${arrivalRunway}.`;
      case 'en':
      default:
        return serializer`Departure ${departureRunway}, arrival ${arrivalRunway}.`;
    }
  }

  serialize<T>({ serializer, ...rest }: SerializeOptions<T>) {
    const { arrivalRunway } = this.properties;

    if (!arrivalRunway) {
      return this.serializeSingleRunway({ serializer, ...rest });
    }

    return this.serializeDepArr({ serializer, ...rest });
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}
