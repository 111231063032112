import React from 'react';
import {
  Serializer,
  SerializerFormat as SerializerFormat_,
} from './serializers';

export type SerializerFormat = SerializerFormat_;

export type Language = 'fr' | 'en';

export type BaseSerializeOptions = {
  lang?: Language;
};

export type SerializeOptions<T extends string | React.ReactNode> = {
  serializer: Serializer<T>;
  lang: Language;
  format: SerializerFormat;
};

interface IChunk {
  type: string;
  serialize<T>(opts: SerializeOptions<T>): T;
}

export abstract class Chunk implements IChunk {
  type = 'Chunk';
  serialize<T extends string | React.ReactNode>(opts: SerializeOptions<T>): T {
    return '' as any;
  }
}

export interface TopLevelChunk<T extends object = {}> extends IChunk {
  toString(opts: Omit<SerializeOptions<string>, 'serializer'>): string;
  toPlainObject(): null | T;
}
