import {
  Chunk,
  BaseSerializeOptions,
  SerializeOptions,
  TopLevelChunk,
} from '../Chunk';
import { createSerializer } from '../serializers';
import { AeroNumber } from '../Elements/AeroNumber';
import { AtisObject } from '../types';

type Props = {
  visibility: number;
};

export class Visibility extends Chunk implements TopLevelChunk<{}> {
  type = 'Visibility' as 'Visibility';
  private properties: Props;

  constructor({ visibility }: Props) {
    super();
    this.properties = {
      visibility:
        visibility > 1000
          ? Math.abs(Math.round(visibility / 100) * 100)
          : visibility,
    };
  }

  getUnit<T>({ serializer, lang, format }: SerializeOptions<T>) {
    switch (lang) {
      case 'fr':
    }
  }

  serialize<T>(opts: SerializeOptions<T>) {
    const { serializer } = opts;

    const { visibility } = this.properties;

    return serializer`${new AeroNumber(visibility, {
      spellOut: false,
      prefix: {
        en: 'Visibility ',
        fr: 'Visibilité ',
      },
      suffix: {
        en: ' meters',
        fr: ' mètres',
      },
    })}.`;
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}
