import React from 'react';
import { Chunk, TopLevelChunk, SerializeOptions } from '../Chunk';
import { createSerializer } from '../serializers';
import { AeroString } from '../Elements/AeroString';
import { AeroTime } from '../Elements/AeroTime';

type Props = {
  airportName: string;
  atisVersion: string;
};

export class Airport extends Chunk implements TopLevelChunk {
  type = 'Airport' as 'Airport';
  private properties: {
    airportName: string;
    atisVersion: AeroString;
  };

  constructor({ airportName, atisVersion }: Props) {
    super();

    this.properties = {
      airportName,
      atisVersion: new AeroString(atisVersion),
    };
  }

  serialize<T extends string | React.ReactNode>({
    serializer,
    lang,
    format,
  }: SerializeOptions<T>) {
    const { atisVersion } = this.properties;

    const airportName =
      format === 'ssml'
        ? `<lang xml:lang="fr-FR">${this.properties.airportName}</lang>`
        : this.properties.airportName;

    switch (lang) {
      case 'fr':
        return serializer`Ici ${airportName}, information ${atisVersion} enregistrée à ${new AeroTime(
          new Date(),
        )}.`;
      case 'en':
      default:
        return serializer`This is ${airportName} information ${atisVersion} recorded at ${new AeroTime(
          new Date(),
        )}.`;
    }
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return this.properties;
  }
}
