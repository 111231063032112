import React from 'react';

import {
  Box,
  Typography,
  Divider,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

const About: React.FC<{}> = () => (
  <Box display="flex" flexDirection="column" padding={1}>
    <Card>
      <CardHeader title="About this website" />
      <CardContent>
        <Typography color="textSecondary">
          This is a proof of concept.
        </Typography>
      </CardContent>
    </Card>
    <Box margin={1} />
    <Card>
      <CardHeader title="How does it work" />
      <CardContent>
        <Typography color="textSecondary">**Work in progress**</Typography>
      </CardContent>
    </Card>
    <Box marginTop={3} margin={1}>
      <Typography align="center" variant="subtitle2" color="textSecondary">
        Build: {process.env.REACT_APP_COMMIT_SHA || 'dev'}
      </Typography>
    </Box>
  </Box>
);

export default About;
