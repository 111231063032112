import * as Yup from 'yup';

export const schema = Yup.object().shape({
  temperature: Yup.number()
    .required('Required')
    .min(-100, 'Minimum value is -100°C')
    .max(100, 'Maximum value is 100°C'),
  dewPoint: Yup.number()
    .required('Required')
    .min(-100, 'Minimum value is -100°C')
    .max(100, 'Maximum value is 100°C'),
});
