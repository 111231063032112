import * as Airport from './Airport';
import * as Runways from './Runways';
import * as TransitionLevel from './TransitionLevel';
import * as Wind from './Wind';
import * as Visibility from './Visibility';
import * as Temperature from './Temperature';
import * as QNH from './QNH';
import * as SkyCoverage from './SkyCoverage';

export {
  Airport,
  Runways,
  TransitionLevel,
  Wind,
  Visibility,
  Temperature,
  QNH,
  SkyCoverage,
};

export const Forms = {
  Airport: Airport.Form,
  Runways: Runways.Form,
  TransitionLevel: TransitionLevel.Form,
  Wind: Wind.Form,
  Visibility: Visibility.Form,
  Temperature: Temperature.Form,
  QNH: QNH.Form,
  SkyCoverage: SkyCoverage.Form,
};

export { AtisBuilder } from './Atis';
