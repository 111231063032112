import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { Visibility as VisibilityChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikEffect from '../../../utils/FormikEffect';
import { InputAdornment } from '@material-ui/core';

import { schema } from './schema';

type Props = ChunkFormProps<VisibilityChunk>;

export const Visibility: React.FC<Props> = ({ onChange }) => {
  const { loading, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    if (!data || !data.decodedMetar?.visibility?.meters) {
      return {
        visibility: '',
      };
    }

    return {
      visibility: `${Math.min(
        10000,
        data.decodedMetar?.visibility.meters_float,
      ) ?? ''}`,
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ values }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new VisibilityChunk({
                  visibility: parseInt(values.visibility, 10),
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <TextField
              variant="outlined"
              name="visibility"
              label="Visibility"
              required={true}
              margin="normal"
              fullWidth={true}
              disabled={loading}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};
