import { Chunk, SerializeOptions, TopLevelChunk } from '../Chunk';
import { AeroNumber } from '../Elements/AeroNumber';
import { createSerializer } from '../serializers';
import { AtisObject } from '../types';

type Props = {
  direction: WindDirection;
  speed: number;
};

export class Wind extends Chunk implements TopLevelChunk<{}> {
  type = 'Wind' as 'Wind';
  private properties: Props;

  constructor(props: { direction: number; speed: number }) {
    super();
    this.properties = {
      direction: new WindDirection(props.direction),
      speed: props.speed,
    };
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    const { direction, speed } = this.properties;
    let prefix = '';
    let suffix = '';
    switch (lang) {
      case 'fr':
        prefix = 'Vent ';
        suffix = ' noeuds';
        break;
      case 'en':
      default:
        prefix = 'Wind ';
        suffix = ' knots';
    }

    return serializer`${prefix}${direction} ${speed}${suffix}.`;
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}

class WindDirection extends Chunk {
  type = 'WindDirection' as 'WindDirection';
  private direction: AeroNumber;

  constructor(direction: number) {
    super();
    this.direction = new AeroNumber(direction, {
      spellOut: true,
      minLength: 3,
    });
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    switch (lang) {
      case 'fr':
        return serializer`${this.direction} degrés`;
      case 'en':
      default:
        return serializer`${this.direction} degrees`;
    }
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }
}
