const baseLang = {
  A: 'alpha',
  B: 'bravo',
  C: 'charlie',
  D: 'delta',
  E: 'echo',
  F: 'fox trot',
  G: 'golf',
  H: 'hotel',
  I: 'india',
  J: 'juliet',
  K: 'kilo',
  L: 'lima',
  M: 'mike',
  N: 'november',
  O: 'oscar',
  P: 'papa',
  Q: 'quebec',
  R: 'romeo',
  S: 'sierra',
  T: 'tango',
  U: 'uniform',
  V: 'victor',
  W: 'whisky',
  X: 'x-ray',
  Y: 'yankee',
  Z: 'zulu',
  1: 'one',
  2: 'two',
  3: 'tree',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'niner',
  0: 'zero',
};

export const letters: Array<keyof typeof baseLang> = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const languages = {
  fr: {
    ...baseLang,
    E: 'écho',
    1: 'unité',
    2: 'deux',
    3: 'trois',
    4: 'quatre',
    5: 'cinq',
    6: 'six',
    7: 'sept',
    8: 'huit',
    9: 'neuf',
    0: 'zéro',
  },
  en: baseLang,
};

function ipa(text: string, ph: string): string {
  return `<phoneme alphabet="ipa" ph="${ph}">${text}</phoneme>`;
}

function sub(text: string, as: string): string {
  return `<sub alias="${as}">${text}</sub>`;
}

function lang(text: string, lang: string): string {
  return `<lang xml:lang="${lang}">${text}</lang>`;
}

// Source: https://en.wikipedia.org/wiki/NATO_phonetic_alphabet

export const ssml = {
  en: {
    ...languages.en,
    A: ipa('alpha', 'ˈælfa'),
    B: ipa('bravo', 'ˈbraːˈvo'),
    C: ipa('charlie', 'ˈtʃɑːli'),
    D: ipa('delta', 'ˈdeltɑ'),
    E: ipa('echo', 'ˈeko'),
    F: ipa('foxtrot', 'ˈfɔkstrɔt'),
    G: ipa('golf', 'ɡʌlf'),
    H: ipa('hotel', 'hoːˈtel'),
    I: ipa('india', 'ˈindi.ɑ'),
    J: ipa('juliett', 'ˈdʒuːli.ˈet'),
    K: ipa('kilo', 'ˈkiːlo'),
    L: ipa('lima', 'ˈliːmɑ'),
    M: ipa('mike', 'mɑik'),
    N: ipa('november', 'noˈvembə'),
    O: ipa('oscar', 'ˈɔskɑ'),
    P: ipa('papa', 'pəˈpɑ'),
    Q: ipa('quebec', 'keˈbek'),
    R: ipa('romeo', 'ˈroːmi.o'),
    S: ipa('sierra', 'siˈerɑ'),
    T: ipa('tango', 'ˈtænɡo'),
    U: ipa('uniform', 'ˈjuːnifɔːm'),
    V: ipa('victor', 'ˈviktɑ'),
    W: ipa('whisky', 'ˈwiski'),
    X: ipa('x-ray', 'ˈeksˈrei'),
    Y: ipa('yankee', 'ˈjænki'),
    Z: ipa('zulu', 'ˈzuːluː'),
  },
  fr: {
    ...languages.fr,
    A: sub('alpha', 'alfah'),
    B: sub('bravo', 'bravo'),
    C: sub('charlie', 'tchar-li'),
    D: sub('delta', 'del-tah'),
    E: sub('echo', 'èk-o'),
    F: sub('foxtrot', 'fox-trott'),
    G: sub('golf', 'golf'),
    H: sub('hotel', 'hotel'),
    I: ipa('india', 'indi.ɑ'),
    J: sub('juliett', 'djouliètt'),
    K: 'kilo',
    L: 'lima',
    M: lang('mike', 'en-US'),
    N: 'novembre',
    O: sub('oscar', 'osskar'),
    P: 'papa',
    Q: sub('quebec', 'kébèk'),
    R: sub('romeo', 'ro-mi-o'),
    S: sub('sierra', 'sièrrah'),
    T: 'tango',
    U: sub('uniform', 'youniform'),
    V: 'victor',
    W: sub('whisky', 'ouisski'),
    X: sub('x-ray', 'èkss-ré'),
    Y: sub('yankee', 'yangki'),
    Z: sub('zulu', 'zoulou'),
  },
};
