import React from 'react';

import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  makeStyles,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  inside: {
    flexGrow: 0,
    flexShrink: 0,
    margin: 'auto',
    minWidth: 400,
  },
}));

const ErrorBox: React.FC<{
  title?: string;
  actions?: null | React.ReactNode;
  retry?: Function;
}> = ({ title, actions, retry, children }) => {
  const classes = useStyles();

  function renderActions() {
    if (actions) {
      return <CardActions>{actions}</CardActions>;
    }

    if (retry && typeof retry === 'function') {
      return (
        <CardActions>
          <Button onClick={() => retry()}>Retry</Button>
        </CardActions>
      );
    }

    return null;
  }

  return (
    <Box display="flex" flexShrink={1}>
      <Card className={classes.inside}>
        {title && <CardHeader title={title} />}
        <CardContent>{children || null}</CardContent>
        {renderActions()}
      </Card>
    </Box>
  );
};

export default ErrorBox;
