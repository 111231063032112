import React from 'react';

import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

import { range } from 'ramda';

const TRANSITION_LEVELS = range(1, 15).map(i => i * 10);

export const TransitionLevelPicker: React.FC<Omit<
  React.ComponentProps<typeof TextField>,
  'select'
>> = ({ disabled, ...rest }) => {
  return (
    <TextField select={true} {...rest}>
      {TRANSITION_LEVELS.map(FL => (
        <MenuItem value={FL} key={FL}>
          FL{FL}
        </MenuItem>
      ))}
    </TextField>
  );
};
