import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  SwipeableDrawer,
  Badge,
  makeStyles,
  Theme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';

import { AtisGenerator } from './AtisGenerator';
import { AtisExplorer } from './AtisExplorer';
import { Playground } from './APIPlayground';
import { Provider as ConfigProvider } from './config';

import { Switch, Route, Redirect } from 'react-router';
import { useLocation, NavLink, Link as RouterLink } from 'react-router-dom';
import Error404 from './utils/Error404';
import About from './About';
import Footer from './Footer';

const useStyles = makeStyles((theme: Theme) => ({
  headerLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const App: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    setMenuOpen(false);
  }, [setMenuOpen, location]);

  return (
    <ConfigProvider
      dialogOpen={settingsOpen}
      onCloseDialog={() => {
        setSettingsOpen(false);
      }}
    >
      <Container
        maxWidth={false}
        disableGutters={true}
        style={{
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setMenuOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Box flexGrow={1}>
              <RouterLink to="/" className={classes.headerLink}>
                <Typography variant="h6" component="h1">
                  ATIS Generator
                </Typography>
              </RouterLink>
            </Box>

            <IconButton
              onClick={() => {
                setSettingsOpen(true);
              }}
              edge="end"
              color="inherit"
            >
              <SettingsIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          open={menuOpen}
          onOpen={() => {
            setMenuOpen(true);
          }}
          onClose={() => {
            setMenuOpen(false);
          }}
        >
          <List>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              to="/atis-generator"
            >
              <ListItemText primary="ATIS Generator" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              to="/atis-explorer"
            >
              <ListItemText primary="ATIS Explorer" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              to="/playground"
            >
              <ListItemText primary="API Playground" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              to="/about"
            >
              <ListItemText primary="About" />
            </ListItem>
          </List>
        </SwipeableDrawer>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          flexShrink={1}
          overflow="auto"
        >
          <Box
            component="main"
            display="flex"
            flexGrow={1}
            flexDirection="column"
          >
            <Switch>
              <Route
                path="/"
                exact
                render={() => <Redirect to="/atis-generator" />}
              />
              <Route path="/atis-generator" render={() => <AtisGenerator />} />
              <Route path="/atis-explorer" render={() => <AtisExplorer />} />
              <Route path="/playground" render={() => <Playground />} />
              <Route path="/about" render={() => <About />} />
              <Route render={() => <Error404 />} />
            </Switch>
          </Box>
          <Footer />
        </Box>
      </Container>
    </ConfigProvider>
  );
};

export default App;
