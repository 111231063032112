import { useContext, useMemo } from 'react';
import { Ctx, Config, Context } from './Context';

export function useConfig(): [Config, Context['setConfig']] {
  const ctx = useContext(Ctx);

  if (!ctx) {
    throw new Error(
      `useConfig must be used with a <ConfigProvider /> component.`,
    );
  }

  const r: [Config, Context['setConfig']] = useMemo(
    () => [ctx.config, ctx.setConfig],
    [ctx],
  );

  return r;
}
