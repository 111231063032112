import {
  Chunk,
  BaseSerializeOptions,
  SerializeOptions,
  TopLevelChunk,
} from '../Chunk';
import { createSerializer } from '../serializers';
import { AeroNumber } from '../Elements/AeroNumber';
import { AtisObject } from '../types';

type Props = {
  temperature: number;
  dewPoint: number;
};

export class Temperature extends Chunk implements TopLevelChunk<{}> {
  type = 'Temperature' as 'Temperature';
  private properties: Props;

  constructor(props: { temperature: number; dewPoint: number }) {
    super();

    this.properties = {
      temperature: props.temperature,
      dewPoint: props.dewPoint,
    };
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    const temperature = new AeroNumber(this.properties.temperature, {
      explicitSign: format !== 'readable',
      suffix: format === 'readable' ? '°C' : '',
    });

    const dewPoint = new AeroNumber(this.properties.dewPoint, {
      explicitSign: format !== 'readable',
      suffix: format === 'readable' ? '°C' : '',
    });

    switch (lang) {
      case 'fr':
        return serializer`Température ${temperature}, point de rosée ${dewPoint}.`;
      case 'en':
      default:
        return serializer`Temperature ${temperature}, dew point ${dewPoint}.`;
    }
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}
