import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { Airport as AirportChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { AtisVersionPicker } from '../../Fields';
import FormikEffect from '../../../utils/FormikEffect';

import { schema } from './schema';

type Props = ChunkFormProps<AirportChunk>;

export const Airport: React.FC<Props> = ({ onChange }) => {
  const { loading, error, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    if (!data) {
      return {
        airportName: '',
        atisVersion: '',
      };
    }

    return {
      airportName: data.name,
      atisVersion: '',
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ submitForm, isValid, values }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new AirportChunk({
                  airportName: values.airportName!,
                  atisVersion: values.atisVersion!,
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <TextField
              variant="outlined"
              name="airportName"
              label="Airport name"
              required={true}
              margin="normal"
              fullWidth={true}
              disabled={loading}
            />
            <AtisVersionPicker
              variant="outlined"
              name="atisVersion"
              label="ATIS Version"
              required={true}
              margin="normal"
              fullWidth={true}
              disabled={loading}
            />
          </>
        );
      }}
    </Formik>
  );
};
