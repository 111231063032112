import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { Wind as WindChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikEffect from '../../../utils/FormikEffect';
import { InputAdornment, Box } from '@material-ui/core';

import { schema } from './schema';

type Props = ChunkFormProps<WindChunk>;

export const Wind: React.FC<Props> = ({ onChange }) => {
  const { loading, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    if (!data?.decodedMetar?.wind) {
      return {
        direction: '',
        speed: '',
      };
    }

    return {
      direction: `${data.decodedMetar.wind.degrees}`,
      speed: `${data.decodedMetar.wind.speed_kts}`,
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ values }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new WindChunk({
                  direction: parseInt(values.direction!, 10),
                  speed: parseInt(values.speed!, 10),
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="50%">
                <TextField
                  variant="outlined"
                  name="direction"
                  type="number"
                  label="Wind direction"
                  disabled={loading}
                  margin="normal"
                  fullWidth={true}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°</InputAdornment>
                    ),
                    inputProps: {
                      min: 1,
                      max: 360,
                    },
                  }}
                />
              </Box>
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="50%">
                <TextField
                  variant="outlined"
                  name="speed"
                  type="number"
                  label="Wind speed"
                  required={true}
                  margin="normal"
                  disabled={loading}
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kts</InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                      max: 120,
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};
