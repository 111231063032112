import React from 'react';

import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { AirportInformation } from '../useAirportInformation';

export const RunwayPicker: React.FC<Omit<
  React.ComponentProps<typeof TextField>,
  'select'
> & {
  includeEmpty?: boolean;
  airportInformation: null | AirportInformation;
}> = ({ airportInformation, includeEmpty = false, ...rest }) => {
  if (
    !airportInformation?.runways ||
    airportInformation?.runways?.length === 0
  ) {
    return <TextField {...rest} />;
  }

  return (
    <TextField select={true} {...rest}>
      {!!includeEmpty && (
        <MenuItem value="" key={'EMPTY'}>
          None
        </MenuItem>
      )}
      {(airportInformation?.runways || []).map(({ ident }) => (
        <MenuItem value={ident} key={ident}>
          {ident || ''}
        </MenuItem>
      ))}
    </TextField>
  );
};
