import React from 'react';
import { Chunk, TopLevelChunk, SerializeOptions } from '../Chunk';
import { createSerializer } from '../serializers';
import { AeroNumber } from '../Elements/AeroNumber';

export type SkyCoverageRate = 'overcast' | 'broken' | 'scattered' | 'few';

export type SkyCoverageLayer = {
  coverage: SkyCoverageRate;
  altitude: number;
};

export type SkyCoverageObject = {};

export class SkyCoverage extends Chunk
  implements TopLevelChunk<SkyCoverageObject> {
  type = 'SkyCoverage';
  private properties: Array<SkyCoverageLayer>;

  constructor({ layers }: { layers: Array<SkyCoverageLayer> }) {
    super();

    this.properties = layers;
  }

  serialize<T extends string | React.ReactNode>({
    serializer,
    lang,
    format,
  }: SerializeOptions<T>) {
    if (this.properties.length === 0) {
      switch (lang) {
        case 'fr':
          return serializer`Ciel clair.`;
        case 'en':
        default:
          return serializer`Sky clear.`;
      }
    }

    return serializer`${this.properties
      .map(v => serializer`${new SkyCoverageLayerChunk(v)}`)
      .join(', ')}.`;
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}

export class SkyCoverageLayerChunk extends Chunk {
  type = 'SkyCoverageLayer';
  private properties: SkyCoverageLayer;

  constructor({ coverage, altitude }: SkyCoverageLayer) {
    super();

    this.properties = {
      coverage,
      altitude: parseInt(`${altitude}`, 10),
    };
  }

  private coverageStrings = {
    en: {
      few: 'Few',
      broken: 'Broken',
      scattered: 'Scattered',
      overcast: 'Overcast',
    },
    fr: {
      few: 'Peu',
      broken: 'Epars',
      scattered: 'Fragmenté',
      overcast: 'Couvert',
    },
  };

  private getCoverageString({
    lang,
    format,
  }: Pick<SerializeOptions<any>, 'format' | 'lang'>): string {
    const coverageString =
      this.coverageStrings[lang]?.[this.properties.coverage] ||
      this.coverageStrings['en'][this.properties.coverage];

    return coverageString;
  }

  serialize<T extends string | React.ReactNode>({
    serializer,
    lang,
    format,
  }: SerializeOptions<T>) {
    return serializer`${this.getCoverageString({
      lang,
      format,
    })} ${new AeroNumber(this.properties.altitude, {
      suffix: { en: ' feet', fr: ' pieds' },
    })}`;
  }
}
