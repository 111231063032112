import React from 'react';

import { Box } from '@material-ui/core';

const Center: React.FC<{}> = ({ children }) => (
  <Box
    display="flex"
    flexGrow={1}
    margin={1}
    flexShrink={1}
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Box>
);

export default Center;
