import React, { useState, useMemo } from 'react';
import { Ctx, Config } from './Context';
import {
  TextField,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

export const Provider: React.FC<{
  dialogOpen: boolean;
  onCloseDialog: () => void;
}> = ({ dialogOpen, children, onCloseDialog }) => {
  const [config, setConfig] = useState<Config>({
    airport: null,
    apiKey: process.env.REACT_APP_API_KEY || null,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  });

  const ctx = useMemo(
    () => ({
      config,
      setConfig,
    }),
    [config, setConfig],
  );

  return (
    <>
      <Ctx.Provider value={ctx}>{children}</Ctx.Provider>
      <Dialog onClose={onCloseDialog} open={dialogOpen}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <TextField
            label="API Key"
            variant="outlined"
            margin="normal"
            value={config.apiKey || ''}
            onChange={event => {
              const value = event.target.value;
              setConfig(prev => ({ ...prev, apiKey: value || null }));
            }}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
