import React from 'react';
import Center from './Center';
import ErrorBox from './ErrorBox';

const Error404: React.FC<{}> = () => {
  return (
    <Center>
      <ErrorBox title="Page not found">
        This page could not be found :(
      </ErrorBox>
    </Center>
  );
};

export default Error404;
