import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { QNH as QNHChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikEffect from '../../../utils/FormikEffect';
import { InputAdornment } from '@material-ui/core';

import { schema } from './schema';

type Props = ChunkFormProps<QNHChunk>;

export const QNH: React.FC<Props> = ({ onChange }) => {
  const { loading, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    return {
      QNH: `${Math.floor(data?.decodedMetar?.barometer.mb || 0) || ''}`,
      QFE: '',
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ submitForm, isValid, values }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new QNHChunk({
                  QNH: parseInt(values.QNH!, 10),
                  QFE: !!values.QFE ? parseInt(values.QFE, 10) : null,
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <TextField
              variant="outlined"
              name="QNH"
              type="number"
              label="QNH"
              required={true}
              margin="normal"
              fullWidth={true}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hPa</InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              name="QFE"
              type="number"
              label="QFE"
              margin="normal"
              fullWidth={true}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">hPa</InputAdornment>
                ),
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};
