import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { Runways as RunwaysChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { AtisVersionPicker } from '../../Fields';
import FormikEffect from '../../../utils/FormikEffect';
import { RunwayPicker } from '../../Fields';

import { schema } from './schema';

type Props = ChunkFormProps<RunwaysChunk>;

export const Runways: React.FC<Props> = ({ onChange }) => {
  const { loading, error, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    return {
      departureRunway: '',
      arrivalRunway: '',
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
      validateOnChange={true}
    >
      {({ values, isValid, isValidating }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new RunwaysChunk({
                  departureRunway: values.departureRunway,
                  arrivalRunway: values.arrivalRunway,
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <RunwayPicker
              airportInformation={data}
              variant="outlined"
              name="departureRunway"
              label="Departure/main runway"
              required={true}
              margin="normal"
              fullWidth={true}
              disabled={loading}
            />
            <RunwayPicker
              airportInformation={data}
              variant="outlined"
              name="arrivalRunway"
              label="Arrival runway"
              margin="normal"
              fullWidth={true}
              includeEmpty={true}
              disabled={loading}
            />
          </>
        );
      }}
    </Formik>
  );
};
