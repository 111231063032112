import * as Yup from 'yup';

export const schema = Yup.object().shape({
  direction: Yup.number()
    .required('Required')
    .min(1, 'Minimum value is 1')
    .max(360, 'Maximum value is 360'),
  speed: Yup.number()
    .required('Required')
    .min(0, 'Minimum value is 0')
    .max(120, 'Maximum value is 120 kts'),
});
