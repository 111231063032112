import { pathOr } from 'ramda';
import { languages as aeroAlphabets, ssml } from '../../../utils/aeroAlphabets';
import { notEmpty } from '../../../utils/notEmpty';
import { Chunk, SerializeOptions } from '../Chunk';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { AeroNumber } from './AeroNumber';
import { AeroString } from './AeroString';

export class AeroTime extends Chunk {
  type = 'AeroTime' as 'AeroTime';
  private value: Date;

  constructor(when: Date) {
    super();
    this.value = utcToZonedTime(when, 'UTC');
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    if (format === 'readable') {
      return serializer`${formatDate(this.value, 'HH:mm')} UTC`;
    }

    const hours = parseInt(formatDate(this.value, 'HH'), 10);
    const minutes = parseInt(formatDate(this.value, 'mm'), 10);

    switch (lang) {
      case 'fr':
        return serializer`${new AeroNumber(hours, {
          spellOut: false,
          suffix: ' heures',
        })} ${new AeroNumber(minutes, {
          spellOut: false,
          minLength: 2,
        })} ${new AeroString('Z', { emphasis: false })}`;
      case 'en':
      default:
        return serializer`${new AeroNumber(hours, {
          spellOut: true,
          minLength: 2,
        })} ${new AeroNumber(minutes, {
          spellOut: true,
          minLength: 2,
        })} ${new AeroString('Z', { emphasis: false })}`;
    }
  }
}
