import {
  Chunk,
  BaseSerializeOptions,
  SerializeOptions,
  TopLevelChunk,
} from '../Chunk';
import { AeroNumber } from '../Elements/AeroNumber';
import { createSerializer } from '../serializers';
import { AtisObject } from '../types';

type Props = {
  transitionLevel: number;
};

export class TransitionLevel extends Chunk implements TopLevelChunk<{}> {
  type = 'TransitionLevel' as 'TransitionLevel';
  private transitionLevel: AeroNumber;

  constructor(props: Props) {
    super();
    this.transitionLevel = new AeroNumber(props.transitionLevel, {
      spellOut: true,
    });
  }

  serialize<T>({ serializer, lang }: SerializeOptions<T>) {
    const { transitionLevel } = this;

    switch (lang) {
      case 'fr':
        return serializer`Niveau de transition ${transitionLevel}.`;
      case 'en':
      default:
        return serializer`Transition level ${transitionLevel}.`;
    }
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}
