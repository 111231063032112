import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { format } from 'date-fns-tz';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

const buildDate = process.env.REACT_APP_BUILD_TIME
  ? new Date(process.env.REACT_APP_BUILD_TIME)
  : null;

const Footer: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container} component="footer">
      <Typography align="center">DGAC / DSNA</Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        component="p"
        align="center"
      >
        Build info: {process.env.REACT_APP_COMMIT_SHA || 'development'}
        {!!buildDate && <>@ {buildDate.toISOString()}</>}
      </Typography>
      {buildDate && (
        <Typography
          variant="caption"
          color="textSecondary"
          component="p"
          align="center"
        >
          Build: {process.env.REACT_APP_COMMIT_SHA || 'development'}
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
