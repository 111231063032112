import { Chunk, SerializeOptions, Language, SerializerFormat } from '../Chunk';
import { AeroString } from './AeroString';

type Options = {
  spellOut?:
    | boolean
    | { [key in Language]: boolean | ((value: number) => boolean) };
  minLength?: number;
  explicitSign?:
    | boolean
    | { [key in Language]: boolean | ((value: number) => boolean) };
  suffix?: string | { [key in Language]: string | ((value: number) => string) };
  prefix?: string | { [key in Language]: string | ((value: number) => string) };
};

export class AeroNumber extends Chunk {
  type = 'AeroNumber' as 'AeroNumber';

  private value: number;
  private options: Required<NonNullable<Options>>;
  constructor(value: number, opts?: Options) {
    super();
    this.value = value;

    const {
      spellOut = false,
      minLength = 1,
      explicitSign = false,
      suffix = '',
      prefix = '',
    } = opts || {};
    this.options = {
      spellOut,
      minLength,
      explicitSign,
      suffix,
      prefix,
    };
  }

  private shouldSpellOut(lang: Language): boolean {
    if (typeof this.options.spellOut === 'boolean') {
      return this.options.spellOut;
    }

    if (!(lang in this.options.spellOut)) {
      return false;
    }

    const fnOrBool = this.options.spellOut[lang];

    if (typeof fnOrBool === 'boolean') {
      return fnOrBool;
    }

    return fnOrBool(this.value);
  }

  private getSuffix(lang: Language): string {
    if (typeof this.options.suffix === 'string') {
      return this.options.suffix;
    }

    if (!(lang in this.options.suffix)) {
      return '';
    }

    const fnOrString = this.options.suffix[lang];

    if (typeof fnOrString === 'string') {
      return fnOrString;
    }

    return fnOrString(this.value);
  }

  private getPrefix(lang: Language): string {
    if (typeof this.options.prefix === 'string') {
      return this.options.prefix;
    }

    if (!(lang in this.options.prefix)) {
      return '';
    }

    const fnOrString = this.options.prefix[lang];

    if (typeof fnOrString === 'string') {
      return fnOrString;
    }

    return fnOrString(this.value);
  }

  private hasExplicitSign(lang: Language): boolean {
    if (typeof this.options.explicitSign === 'boolean') {
      return this.options.explicitSign;
    }

    if (!(lang in this.options.explicitSign)) {
      return false;
    }

    const fnOrBool = this.options.explicitSign[lang];

    if (typeof fnOrBool === 'boolean') {
      return fnOrBool;
    }

    return fnOrBool(this.value);
  }

  private getSignString({
    lang,
    format,
  }: {
    lang: Language;
    format: SerializerFormat;
  }) {
    if (this.value === 0) {
      return '';
    }

    const isNegative = this.value < 0;
    const showSign = this.hasExplicitSign(lang) || isNegative;

    if (!showSign) {
      return '';
    }

    if (format === 'readable') {
      return isNegative ? '-' : '+';
    }

    const table = {
      fr: {
        plus: {
          speakable: 'plus ',
          ssml: '<phoneme alphabet="ipa" ph="plys">plus </phoneme> ',
        },
        minus: {
          speakable: 'moins ',
          ssml: 'moins ',
        },
      },
      en: {
        plus: {
          speakable: 'plus ',
          ssml: 'plus ',
        },
        minus: {
          speakable: 'minus ',
          ssml: 'minus ',
        },
      },
    };

    return (table[lang] || table['en'])[isNegative ? 'minus' : 'plus'][format];
  }

  serialize<T>({ serializer, format, lang }: SerializeOptions<T>) {
    const paddedValue = `${Math.abs(this.value)}`.padStart(
      this.options.minLength,
      '0',
    );

    if (format === 'readable' || !this.shouldSpellOut(lang)) {
      return serializer`${this.getPrefix(lang)}${this.getSignString({
        format,
        lang,
      })}${paddedValue}${this.getSuffix(lang)}`;
    }

    return serializer`${this.getPrefix(lang)}${this.getSignString({
      format,
      lang,
    })}${paddedValue
      .split('')
      .map(v => serializer`${new AeroString(v)}`)
      .join(' ')}${this.getSuffix(lang)}`;
  }
}
