import { useFormikContext, FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Props<T> = {
  onChange: (
    props: Pick<FormikProps<T>, 'values' | 'isValid' | 'errors' | 'touched'>,
  ) => void;
  debounce?: boolean;
  debounceMs?: number;
};

function FormikEffect<T>({
  onChange,
  debounce = true,
  debounceMs = 100,
}: Props<T>) {
  const { values, isValid, errors, touched, isValidating } = useFormikContext<
    T
  >();

  const ref = useRef<typeof onChange>(onChange);

  const [debouncedOnChange, cancel] = useDebouncedCallback(
    (
      props: Pick<FormikProps<T>, 'values' | 'isValid' | 'errors' | 'touched'>,
    ) => {
      if (isValidating) {
        return;
      }

      if (!!ref.current) {
        onChange({ values, isValid, errors, touched });
      }

      return () => {
        cancel();
      };
    },
    debounce ? debounceMs : 200,
  );

  useEffect(() => {
    debouncedOnChange({ values, isValid, errors, touched });
  }, [values, isValid, errors, touched, debouncedOnChange]);

  return null;
}

export default FormikEffect;
