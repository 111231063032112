import * as Yup from 'yup';
import { runway } from '../../../utils/regex';

export const schema = Yup.object().shape({
  departureRunway: Yup.string()
    .required('Required')
    .matches(runway, 'Invalid runway identifier'),
  arrivalRunway: Yup.string().matches(runway, {
    message: 'Invalid runway identifier',
    excludeEmptyString: true,
  }),
});
