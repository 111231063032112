import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { SkyCoverage as SkyCoverageChunk, SkyCoverageLayer } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikEffect from '../../../utils/FormikEffect';
import {
  Box,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  CardActions,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import SunIcon from '@material-ui/icons/Brightness5';

import { schema } from './schema';

type Props = ChunkFormProps<SkyCoverageChunk>;

const transformCoverageCode = {
  BKN: 'broken' as const,
  SCT: 'scattered' as const,
  OVC: 'overcast' as const,
  FEW: 'few' as const,
};

export const SkyCoverage: React.FC<Props> = ({ onChange }) => {
  const { loading, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    return {
      layers: (data?.decodedMetar?.clouds || []).map(c => {
        return {
          coverage: transformCoverageCode[c.code],
          altitude: c.base_feet_agl,
        };
      }),
      bla: [] as Array<SkyCoverageLayer>,
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ submitForm, isValid, setFieldValue, values }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values, errors }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new SkyCoverageChunk({
                  layers: values.layers,
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            {values.layers.map((v, idx) => (
              <React.Fragment key={idx}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <TextField
                    variant="outlined"
                    name={`layers[${idx}].coverage`}
                    label="Coverage"
                    required={true}
                    margin="normal"
                    fullWidth={true}
                    select={true}
                    disabled={loading}
                    helperText="Sky coverage"
                  >
                    <MenuItem value="overcast">Overcast</MenuItem>
                    <MenuItem value="broken">Broken</MenuItem>
                    <MenuItem value="scattered">Scattered</MenuItem>
                    <MenuItem value="few">Few</MenuItem>
                  </TextField>
                  <TextField
                    variant="outlined"
                    name={`layers[${idx}].altitude`}
                    type="number"
                    label="Altitude"
                    required={true}
                    margin="normal"
                    fullWidth={true}
                    disabled={loading}
                    helperText="Altitude in feet"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              const layers = values.layers.filter(
                                (v, i) => i !== idx,
                              );
                              setFieldValue('layers', layers);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </React.Fragment>
            ))}
            <CardActions>
              <Button
                variant="outlined"
                onClick={() => {
                  setFieldValue('layers', []);
                }}
                startIcon={<SunIcon />}
              >
                Sky clear
              </Button>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  setFieldValue('layers', [
                    ...values.layers,
                    { coverage: 'few', altitude: 0 },
                  ]);
                }}
              >
                Add layer
              </Button>
            </CardActions>
          </>
        );
      }}
    </Formik>
  );
};
