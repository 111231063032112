import { pathOr } from 'ramda';
import { languages as aeroAlphabets, ssml } from '../../../utils/aeroAlphabets';
import { notEmpty } from '../../../utils/notEmpty';
import { Chunk, SerializeOptions } from '../Chunk';

type Options = {
  emphasis: boolean;
};

export class AeroString extends Chunk {
  type = 'AeroString' as 'AeroString';
  private value: string;
  private options: Options;

  constructor(str: string, { emphasis = true }: Partial<Options> = {}) {
    super();
    this.value = str.toUpperCase();
    this.options = {
      emphasis,
    };
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    if (format === 'readable') {
      return serializer`${this.value}`;
    }

    switch (lang) {
      case 'fr':
      case 'en':
      default:
        return serializer`${this.value
          .split('')
          .map(char =>
            pathOr(
              null,
              [lang, char],
              format === 'ssml' ? ssml : aeroAlphabets,
            ),
          )
          .filter(notEmpty)
          .map(c =>
            format === 'ssml' && this.options.emphasis
              ? `<emphasis>${c}</emphasis>`
              : c,
          )
          .join(' ')}`;
    }
  }
}
