import React from 'react';
import { Chunk, Language } from '../Chunk';
import { humanReadable } from './humanReadable';

export type Serializer<T extends string | React.ReactNode> = (
  literals: TemplateStringsArray,
  ...chunks: Array<null | undefined | number | string | Chunk>
) => T;

export type SerializerFormat = 'readable' | 'speakable' | 'ssml';

export function createSerializer<T extends string | React.ReactNode>({
  lang = 'en',
  format = 'readable',
}: {
  lang?: Language;
  format: SerializerFormat;
}): Serializer<T> {
  function serializer(
    literals: TemplateStringsArray,
    ...chunks: Array<null | undefined | number | string | T | Chunk>
  ): T {
    const processChunk = (
      chunk: null | undefined | number | string | T | Chunk,
    ) => {
      if (chunk === null || chunk === undefined) {
        return '';
      } else if (typeof chunk === 'number') {
        return `${chunk}`;
      } else if (chunk instanceof Chunk) {
        // console.log(
        //   `Calling serialize on chunk ${chunk.type} ${lang} ${format}`,
        // );

        return chunk.serialize({ serializer, lang, format });
      }

      return chunk;
    };

    let result = '';

    literals.forEach((c, i) => {
      result += c + processChunk(chunks[i]);
    });

    return result as T;
  }

  return serializer;
}
