import React, { useMemo } from 'react';
import { ChunkFormProps } from '../ChunkFormProps';
import { Temperature as TemperatureChunk } from './Chunk';

import { useAirportInformation } from '../../useAirportInformation';

import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikEffect from '../../../utils/FormikEffect';
import { InputAdornment, Box } from '@material-ui/core';

import { schema } from './schema';

type Props = ChunkFormProps<TemperatureChunk>;

export const Temperature: React.FC<Props> = ({ onChange }) => {
  const { loading, data } = useAirportInformation();

  const initialValues = useMemo(() => {
    return {
      temperature: `${data?.decodedMetar?.temperature?.celsius ?? ''}`,
      dewPoint: `${data?.decodedMetar?.dewpoint?.celsius ?? ''}`,
    };
  }, [data]);

  return (
    <Formik
      key={data?.ICAO}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
      validateOnMount={true}
    >
      {({ values }) => {
        return (
          <>
            <FormikEffect<typeof values>
              onChange={({ isValid, values }) => {
                if (!isValid) {
                  onChange({
                    isValid: false,
                    error: 'Validation error',
                    chunk: null,
                  });
                  return;
                }

                const chunk = new TemperatureChunk({
                  temperature: parseInt(values.temperature!, 10),
                  dewPoint: parseInt(values.dewPoint!, 10),
                });

                onChange({
                  isValid: true,
                  error: null,
                  chunk,
                });
              }}
            />
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="50%">
                <TextField
                  variant="outlined"
                  name="temperature"
                  type="number"
                  label="Temperature"
                  disabled={loading}
                  margin="normal"
                  fullWidth={true}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°C</InputAdornment>
                    ),
                    inputProps: {
                      min: -100,
                      max: 100,
                    },
                  }}
                />
              </Box>
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="50%">
                <TextField
                  variant="outlined"
                  name="dewPoint"
                  type="number"
                  label="Dew point"
                  disabled={loading}
                  margin="normal"
                  fullWidth={true}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°C</InputAdornment>
                    ),
                    inputProps: {
                      min: -100,
                      max: 100,
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        );
      }}
    </Formik>
  );
};
