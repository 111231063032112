import React from 'react';

type Props = {
  src?: string;
};

const Player: React.FC<Props> = ({ src }) => {
  return <audio controls src={src} preload="auto" />;
};

export default Player;
