import { Chunk, SerializeOptions } from '../Chunk';
import { runway as regex } from '../../../utils/regex';
import { AeroNumber } from './AeroNumber';

export class Runway extends Chunk {
  type = 'Runway' as 'Runway';
  private QFU: QFU;
  private LeftRightCenter: RunwayVariant | null;

  constructor(runway: string) {
    super();
    const capture = regex.exec(runway);

    if (!capture) {
      throw new Error(`${runway} is not a valid runway identifier !`);
    }

    const { groups } = capture;

    this.QFU = new QFU(parseInt(groups!.QFU, 10));
    this.LeftRightCenter = groups!.variant
      ? new RunwayVariant(groups!.variant as any)
      : null;
  }

  serialize<T>({ serializer, lang }: SerializeOptions<T>) {
    switch (lang) {
      case 'fr':
        return serializer`${this.QFU}${this.LeftRightCenter}`;
      case 'en':
      default:
        return serializer`${this.QFU}${this.LeftRightCenter}`;
    }
  }
}

class RunwayVariant extends Chunk {
  type = 'RunwayVariant' as 'RunwayVariant';
  private value: 'C' | 'R' | 'L';

  private spoken = {
    en: {
      C: 'center',
      R: 'right',
      L: 'left',
    },
    fr: {
      C: 'centre',
      R: 'droite',
      L: 'gauche',
    },
  };

  constructor(value: 'C' | 'R' | 'L') {
    super();
    this.value = value;
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    if (format === 'speakable' || format === 'ssml') {
      return serializer` ${this.spoken[lang][this.value]}`;
    }

    switch (lang) {
      case 'fr':
        return serializer`${this.value}`;
      case 'en':
      default:
        return serializer`${this.value}`;
    }
  }
}

class QFU extends Chunk {
  type = 'QFU' as 'QFU';
  private value: AeroNumber;

  constructor(value: number) {
    super();
    this.value = new AeroNumber(value, {
      spellOut: { fr: val => val < 10, en: true },
      minLength: 2,
    });
  }

  serialize<T>({ serializer, lang, format }: SerializeOptions<T>) {
    return serializer`${this.value}`;
  }
}
