import React, {
  useReducer,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';

import Player from './Player';
import { Button, Box, LinearProgress, Typography } from '@material-ui/core';
import { AtisBuilder } from '../chunks';
import { Language as AtisLanguage } from '../chunks/Chunk';
import { useConfig } from '../../config';

type Props = {
  builder: AtisBuilder;
  lang?: AtisLanguage;
};

type State = {
  loading: boolean;
  error: Error | null;
  data: null | Data;
};

type Data = {
  url: string;
};

type Action =
  | { type: 'start' }
  | { type: 'end'; data: Data }
  | { type: 'error'; error: Error };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start': {
      return {
        loading: true,
        error: null,
        data: null,
      };
    }
    case 'end': {
      return {
        loading: false,
        error: null,
        data: action.data,
      };
    }
    case 'error': {
      return {
        loading: false,
        error: action.error,
        data: null,
      };
    }
  }
}

const VOICES = {
  en: 'Joanna',
  fr: 'Lea',
};

const Preview: React.FC<Props> = ({ builder, lang = 'en' }) => {
  const [config] = useConfig();
  const [ssml, setSSML] = useState<null | string>(null);
  const [tryCount, setTryCount] = useState<number>(0);
  const retry = useCallback(() => {
    setTryCount(prev => prev + 1);
  }, [setTryCount]);
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    data: null,
    error: null,
  });

  const configRef = useRef(config);
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  useEffect(() => {
    if (!ssml || !configRef.current.apiKey) {
      return;
    }

    let isMounted = true;

    dispatch({ type: 'start' });

    const url = new URL('api/atis/preview', configRef.current.apiBaseUrl);

    fetch(url.href, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(configRef.current.apiKey)}`,
      },
      body: JSON.stringify({
        voiceId: VOICES[lang],
        text: ssml,
      }),
    })
      .then(res => {
        if (res.status !== 200) {
          return res.json().then(err => Promise.reject(err));
        }

        return res.json();
      })
      .then(
        res => {
          if (!isMounted) {
            return;
          }

          dispatch({ type: 'end', data: res });
        },
        error => {
          if (!isMounted) {
            return;
          }

          dispatch({ type: 'error', error });
        },
      );

    return () => {
      isMounted = false;
    };
  }, [ssml, dispatch, lang, configRef, tryCount]);

  return (
    <Box display="flex" flexDirection="column">
      <Button
        disabled={state.loading || !builder.isValid() || !config.apiKey}
        onClick={() => {
          if (!builder.isValid()) {
            return;
          }

          const ssml = builder.toString({
            lang,
            format: 'ssml',
          });

          setSSML(ssml);
          retry();
        }}
        variant="outlined"
      >
        Preview
      </Button>
      {!config.apiKey && (
        <Typography color="error">
          An API key must be configured in the app settings before allowing
          previews.
        </Typography>
      )}
      {!builder.isValid() && (
        <Typography color="error">
          Current ATIS is incomplete. Please finish filling the form.
        </Typography>
      )}
      {state.error && (
        <Typography color="error">
          An error happened:
          <br />
          {state.error.message}
        </Typography>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop={1}
      >
        {state.loading ? (
          <LinearProgress style={{ width: '100%' }} />
        ) : (
          state.data?.url && <Player src={state.data.url} />
        )}
      </Box>
    </Box>
  );
};

export default Preview;
