import { useReducer, useEffect, useCallback, useMemo, Reducer } from 'react';

type HookReturn<D> = {
  loading: boolean;
  error: null | Error;
  data: null | D;
  refetch: () => void;
};

type State<D> = {
  loading: boolean;
  error: null | Error;
  data: null | D;
  refetchCount: number;
};

type Action<D> =
  | { type: 'start'; clear?: boolean }
  | { type: 'end'; data: D }
  | { type: 'error'; error: Error }
  | { type: 'refetch' };

function reducer<D>(state: State<D>, action: Action<D>): State<D> {
  switch (action.type) {
    case 'start': {
      return {
        ...state,
        loading: true,
        error: null,
        data: !!action.clear ? null : state.data,
      };
    }
    case 'end': {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data,
      };
    }
    case 'error': {
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      };
    }
    case 'refetch': {
      return {
        ...state,
        refetchCount: state.refetchCount + 1,
      };
    }
  }
}

const delay = (d: number) => new Promise(resolve => setTimeout(resolve, d));

export function useFetch<Data>({
  url,
}: {
  url: string | null;
}): HookReturn<Data> {
  const [state, dispatch] = useReducer<Reducer<State<Data>, Action<Data>>>(
    reducer,
    {
      loading: false,
      data: null as null | Data,
      error: null,
      refetchCount: 0,
    },
  );

  const refetch = useCallback(() => {
    dispatch({ type: 'refetch' });
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;

    if (!url) {
      return;
    }

    dispatch({ type: 'start' });

    delay(0)
      .then(() => fetch(url))
      .then(res => {
        if (res.status === 404) {
          throw new Error(`Airport could not be found.`);
        }

        if (res.status === 400) {
          throw new Error(`Invalid airport code`);
        }

        if (res.status !== 200) {
          throw new Error(`Invalid status code: ${res.status}`);
        }

        return res.json();
      })
      // .then(() => LFBO as any)
      .then(
        res => {
          if (!isMounted) {
            return;
          }

          dispatch({ type: 'end', data: res });
        },
        error => {
          if (!isMounted) {
            return;
          }

          dispatch({ type: 'error', error });
        },
      );

    return () => {
      isMounted = false;
    };
  }, [url, state.refetchCount]);

  const r = useMemo(
    () => ({
      loading: state.loading,
      error: state.error,
      data: state.data,
      refetch,
    }),
    [state, refetch],
  );

  return r;
}
