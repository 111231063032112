import React from 'react';
import { useConfig } from '../config';
import Center from '../utils/Center';
import ErrorBox from '../utils/ErrorBox';

const Main: React.FC<{}> = () => {
  const [config] = useConfig();

  if (!config.apiKey) {
    return (
      <Center>
        <ErrorBox title="No API Key configured !">
          Please define an API key in the application settings.
        </ErrorBox>
      </Center>
    );
  }

  return <div>Bla !</div>;
};

export default Main;
