import { Chunk, TopLevelChunk, SerializeOptions } from './Chunk';
import { notEmpty } from '../../utils/notEmpty';
import { createSerializer } from './serializers';
import { AtisObject } from './types';

export class AtisBuilder implements TopLevelChunk<AtisObject> {
  type = 'AtisBuilder';
  private properties: { icaoId: null | string };
  private chunks: Array<Chunk>;

  constructor({
    chunks,
    icaoId,
  }: {
    icaoId: string | null;
    chunks: Array<null | undefined | Chunk>;
  }) {
    this.chunks = chunks.filter(notEmpty);
    this.properties = {
      icaoId,
    };
  }

  serialize<T>(opts: SerializeOptions<T>): any {
    throw new Error('AtisBuilder.serialize method is not callable');
  }

  private hasChunk(str: string): boolean {
    return !!this.chunks.find(({ type }) => type === str);
  }

  isValid(): boolean {
    return (
      this.hasChunk('Airport') &&
      this.hasChunk('Runways') &&
      this.hasChunk('TransitionLevel') &&
      this.hasChunk('Wind') &&
      this.hasChunk('SkyCoverage') &&
      this.hasChunk('Temperature') &&
      this.hasChunk('Visibility') &&
      this.hasChunk('QNH')
    );
  }

  toPlainObject() {
    const airportChunk = this.chunks.find(({ type }) => type === 'Airport');

    return null;
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer(o);

    const res = [
      ...this.chunks.map(c =>
        c.serialize({
          serializer,
          ...o,
        }),
      ),
      // new AeroString('ABCDEFGHIJKLMNOPQRSTUVWXYZ').serialize({
      //   serializer,
      //   ...o,
      // }),
    ].join('\n');

    if (opts.format === 'ssml') {
      return `<speak>${res}</speak>`;
    }

    return res;
  }
}
