import React from 'react';
import { Chunk, SerializeOptions, TopLevelChunk } from '../Chunk';
import { createSerializer } from '../serializers';
import { AeroNumber } from '../Elements/AeroNumber';
import { AtisObject } from '../types';

export class QNH extends Chunk implements TopLevelChunk {
  type = 'QNH';
  private properties: {
    QNH: number;
    QFE: null | number;
  };

  constructor({ QNH, QFE }: { QNH: number; QFE: null | number }) {
    super();

    this.properties = {
      QNH,
      QFE: QFE ?? null,
    };
  }

  serialize<T extends string | React.ReactNode>({
    serializer,
    lang,
    format,
  }: SerializeOptions<T>) {
    const QNH = new AeroNumber(this.properties.QNH, {
      spellOut: true,
      prefix:
        format === 'ssml'
          ? '<say-as interpret-as="spell-out">QNH</say-as> '
          : 'QNH ',
    });

    let QFE: string | AeroNumber = '';

    if (this.properties.QFE !== null) {
      let prefix = ', QFE ';

      if (format === 'ssml') {
        prefix = ', <say-as interpret-as="spell-out">QFE</say-as> ';
      }

      QFE = new AeroNumber(this.properties.QFE, {
        spellOut: true,
        prefix,
      });
    }

    return serializer`${QNH}${QFE}.`;
  }

  toString(opts: Omit<SerializeOptions<string>, 'serializer'>) {
    const o = {
      lang: opts?.lang ?? 'en',
      format: opts?.format ?? 'readable',
    };

    const serializer = createSerializer<string>(o);

    return this.serialize({ serializer, ...o });
  }

  toPlainObject() {
    return null;
  }
}
