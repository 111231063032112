import { indigo, deepOrange } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: indigo,
    secondary: deepOrange,
  },
});

export default theme;
