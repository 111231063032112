import React, { useContext, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { useFetch } from '../utils/useFetch';
import parseMetar, { Metar } from 'aewx-metar-parser';

export type AirportInformation = {
  ICAO: string;
  name: string;
  lat: number;
  lon: number;
  runwayCount: number;
  runways: Array<AirportInformation_Runway>;
  weather: {
    METAR: string;
    TAF: string;
  };
  decodedMetar?: null | Metar;
};

type AirportInformation_Runway = {
  ident: string;
  width: number;
  length: number;
  bearing: number;
  surface: string;
  ends: [AirportInformation_Runway_End, AirportInformation_Runway_End];
};

type AirportInformation_Runway_End = {
  ident: string;
};

type AirportInformationContext = {
  loading: boolean;
  data: null | AirportInformation;
  error: null | Error;
  refetch: () => void;
};

export const Ctx = React.createContext<null | AirportInformationContext>(null);

export function useFetchAirportInformation({
  icaoId,
}: {
  icaoId: null | string;
}): AirportInformationContext {
  const [debouncedIcaoId] = useDebounce(icaoId, 2000);

  const url = useMemo(
    () =>
      !!debouncedIcaoId
        ? `https://api.flightplandatabase.com/nav/airport/${debouncedIcaoId}`
        : null,
    [debouncedIcaoId],
  );

  const { loading, error, data, refetch } = useFetch<AirportInformation>({
    url,
  });

  const enhancedData = useMemo(() => {
    if (!data?.weather?.METAR) {
      return data;
    }

    try {
      const decodedMetar = parseMetar(data.weather.METAR);

      return {
        ...data,
        decodedMetar,
      };
    } catch (err) {
      return data;
    }
  }, [data]);

  return {
    loading: loading || icaoId !== debouncedIcaoId,
    error,
    data: enhancedData,
    refetch,
  };
}

export const AirportInformationProvider: React.FC<{
  icaoId: null | string;
}> = ({ icaoId, children }) => {
  const value = useFetchAirportInformation({ icaoId });

  return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};

export function useAirportInformation(): AirportInformationContext {
  const ctx = useContext(Ctx);

  if (!ctx) {
    throw new Error(
      'useAirportInformation must be used as a descendant of an AirportInformationProvider',
    );
  }

  return ctx;
}
